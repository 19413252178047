<template>
    <div class="card text-white">
        <img class="card-img" src="../../assets/img/sl1.png" alt="Card image">
        <div class="card-img-overlay">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">Last updated 3 mins ago</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TopNews"
    }
</script>

<style scoped>
.card-img{
    width: 475px;
    height: 245px;
}
.card{
    width: 475px;
    height: 245px;
}
</style>
