<template>
    <div class="main-right">
        <div class="main-right-heading">
            <p>Top News</p>
        </div>
        <div class="live-slider">
                <TopNews></TopNews>
        </div>
    </div>
</template>

<script>
    import TopNews from "../components/NewsCore/TopNews";
    export default {
        name: "News",
        components:{
            TopNews
        }
    }
</script>

<style scoped>
    .live-slider {
        position: relative;

    }
    .live-slider {
        /*background-color: #171C22;*/
        padding-bottom: 40px;
    }
    .main-right-heading p {
        font-size: 22px;
        font-weight: 500;
        color: #fff;
    }
    .main-right-heading {
        padding: 40px 60px;
        /*padding: 40px 0;*/
        background: #1e2124;
        /*padding-left: 60px;*/
    }
</style>
