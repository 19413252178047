<template>
    <div>
        <div class="owl-carousel products-slider owl-theme">
            <TopNewsItem></TopNewsItem>
        </div>
    <b-button @click="sendSoap">Send Soap</b-button>
    </div>
</template>

<script>
    import TopNewsItem from "./TopNewsItem";
    import axios from "../../axios";

    export default {
        name: "TopNews",
        components: {
          TopNewsItem
        },
      data(){
        const today = new Date();
        return{
          tokens: null,
        }
      },
        methods: {

        }
    }
</script>

<style >
    .owl-prev {
        width: 45px;
        height: 45px;
        background-color: rgba(0, 0, 0, 0.55);
        border-radius: 50%;
        padding: 9px 14px;
        cursor: pointer;
    }

    .owl-prev img {
        display: inline-block;
        width: 11px;
    }

    .owl-next {
        width: 45px;
        height: 45px;
        background-color: rgba(0, 0, 0, 0.55);
        border-radius: 50%;
        padding: 9px 17px;
        cursor: pointer;
    }

    .owl-next img {
        display: inline-block;
        width: 11px;
    }
    .owl-prev {
        position: absolute;
        left: 25px;
        top: 40%;
        z-index: 500;
    }

    .owl-next {
        position: absolute;
        right: 35px;
        top: 40%;
        z-index: 500;
    }
</style>
